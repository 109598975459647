<template>
    <div
        class="wit-table"
        v-bind:class="{
            'wit-table__paginated': paginated,
            'wit-table__borderless': borderless,
        }"
    >
        <b-form-input
            v-if="search"
            id="filter-input"
            placeholder="Type to Search"
            type="search"
            v-model="queryParams.query"
            @input="onQueryInput"
        ></b-form-input>

        <b-table
            :hover="false"
            :striped="striped"
            :responsive="true"
            :items="internal.items"
            :fields="internal.fields"
            :tbody-tr-class="rowClass"
            :show-empty="true"
            :empty-text="showErrorText ? errorText : emptyText"
            :busy="internal.busy"
            class="b-table-opaque"
            v-bind="paginationProps"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope" />
            </template>

            <template v-if="isRowDetails" v-slot:cell(details)="row">
                <div v-if="!row.item.hideToggler" class="wit-table__toggle-details-div">
                    <span v-b-tooltip.hover title="Toggle Details" @click="row.toggleDetails">
                        <i
                            class="fa pointer-action wit-table__toggle-details"
                            v-bind:class="{
                                'fa-angle-double-down': !row.detailsShowing,
                                'fa-angle-double-up': row.detailsShowing,
                            }"
                        />
                    </span>
                </div>
            </template>
            <template v-slot:table-busy>
                <div class="text-center">
                    <b-spinner class="spinner-running" md label="Loading" variant="primary"></b-spinner>
                </div>
            </template>
        </b-table>
        <b-row align-h="center" v-if="infiniteScroll" class="retrieve-more-row">
            <span v-b-tooltip.hover title="Retrieve more" v-if="!internal.noMoreRecords">
                <i
                    v-if="!internal.scrollBusy"
                    @click="() => fetchData({silent: true})"
                    class="pointer-action retrieve-more-action fa fa-angle-double-down"
                />
                <b-spinner v-else class="spinner-running" md label="Loading" variant="primary"></b-spinner>
            </span>
            <span v-b-tooltip.hover title="No more reports" v-else>
                <i class="fa fa-angle-double-down retrieve-more-action disabled-retrieve-more-action" />
            </span>
        </b-row>
        <div v-if="paginated && internal.count > 0" class="b-table-pagination-wrapper">
            <div class="b-table-pagination">
                <div class="b-table-pagination-per-page-wrapper" v-if="!hidePerPageSelector">
                    Show
                    <wit-select
                        class="b-table-pagination-per-page"
                        v-model="perPage"
                        :options="[5, 10, 25, 50, 100]"
                        :inline="true"
                        size="sm"
                    ></wit-select
                    >entries
                </div>

                <div class="b-pagination-wrapper">
                    <b-pagination
                        v-model="page"
                        :total-rows="internal.count"
                        :per-page="perPage"
                        @input="() => (this.mode === 'server' ? this.fetchData() : undefined)"
                    ></b-pagination>
                    <p class="current-page">
                        Showing {{ 1 + (page - 1) * perPage }} to {{ Math.min(page * perPage, internal.count) }} of
                        {{ internal.count }} records
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wit-table',
    props: {
        mode: {
            type: String,
            default: 'client',
            // 'client', 'server'
        },
        items: {
            type: Array,
        },
        requestFunction: {
            type: Function,
            required: false,
        },
        fields: {
            type: Array,
            required: true,
        },
        rowClass: {
            type: Function,
            default: () => () => undefined,
        },
        striped: {
            type: Boolean,
            default: false,
        },
        borderless: {
            type: Boolean,
            default: true,
        },
        paginated: {
            type: Boolean,
            default: false,
        },
        infiniteScroll: {
            type: Boolean,
            default: false,
        },
        hidePerPageSelector: {
            type: Boolean,
            default: false,
        },
        defaultPerPage: {
            type: Number,
            default: 5,
        },
        busy: {
            type: Boolean,
            default: false,
        },
        customQueryParams: {
            type: Object,
            default: () => {},
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        search: {
            type: Boolean,
            default: false,
        },
        emptyText: {
            type: String,
            default: 'There are no records to show',
        },
        errorText: {
            type: String,
            default: 'An error occured',
        },
    },
    data() {
        return {
            page: 1,
            perPage: 5,
            queryTimeout: null,
            queryParams: {
                query: '',
                ascending: 0,
                byColumn: 0,
            },
            scrollParams: {
                cursorId: '',
            },
            internal: {
                items: [],
                fields: [],
                count: 0,
                busy: false,
                scrollBusy: false,
                noMoreRecords: false,
            },
            isRowDetails: false,
            showErrorText: false,
        }
    },
    computed: {
        paginationProps() {
            return this.mode === 'client' && this.paginated
                ? {
                      'current-page': this.page,
                      'per-page': this.perPage,
                  }
                : {}
        },
    },
    created() {
        this.setInternalFields()

        if (this.busy) {
            this.internal.busy = this.busy
        }
        if (this.items) {
            this.internal.count = this.items.length
            this.internal.items = [...this.items]
        }
        if (this.defaultPerPage) {
            this.perPage = this.defaultPerPage
        }
        if (this.mode === 'server') {
            this.fetchData()
        }
    },
    beforeUpdate() {
        this.setInternalFields()
    },
    watch: {
        defaultPerPage() {
            if (this.defaultPerPage) {
                this.perPage = this.defaultPerPage
            }
        },
        items() {
            this.internal.count = this.items.length
            this.internal.items = [...this.items]
        },
        busy() {
            this.internal.busy = this.busy
        },
        fields() {
            return this.setInternalFields()
        },
        $props: {
            immediate: true,
            handler() {
                this.validateProps()
            },
        },
    },
    methods: {
        validateProps() {
            if (this.mode === 'client') {
                if (!this.items) {
                    const errorMessage = 'When using client table items must be passed as a prop'
                    throw new Error(errorMessage)
                }
            }
            if (this.mode === 'server') {
                if (!this.requestFunction) {
                    const errorMessage = 'When using server table requestFunction must be passed as a prop'
                    throw new Error(errorMessage)
                }
            }
        },
        onQueryInput() {
            if (this.queryTimeout) {
                clearTimeout(this.queryTimeout)
            }

            this.queryTimeout = setTimeout(() => this.fetchData(), 500)
        },
        async fetchData(options) {
            if (this.mode === 'client') return

            if ((options && !options.silent) || !options) {
                this.internal.busy = true
            }
            this.internal.scrollBusy = true

            this.showErrorText = false

            try {
                const conditionalParams = this.infiniteScroll ? this.scrollParams : {}

                const {data, count, cursorId} = await this.requestFunction({
                    page: this.page,
                    limit: this.perPage,
                    ...this.queryParams,
                    ...this.customQueryParams,
                    ...conditionalParams,
                })

                const newItems = data.map(el => {
                    const itemFound = this.internal.items.find(item => item[this.trackBy] === el[this.trackBy])
                    return itemFound ? {...itemFound, ...el} : el
                })

                this.internal.items = this.infiniteScroll ? [...this.internal.items, ...newItems] : newItems
                this.internal.count = count

                if (this.infiniteScroll) {
                    if (cursorId === this.scrollParams.cursorId || this.internal.items.length < this.perPage) {
                        this.internal.noMoreRecords = true
                    }
                    this.scrollParams.cursorId = cursorId
                }

                if ((options && !options.silent) || !options) {
                    this.internal.busy = false
                }
                this.internal.scrollBusy = false
            } catch (e) {
                if ((options && !options.silent) || !options) {
                    this.internal.busy = false
                }
                this.internal.scrollBusy = false

                this.showErrorText = true
            }
        },
        async refreshScroll() {
            this.showErrorText = false

            try {
                const {data, cursorId} = await this.requestFunction({
                    page: this.page,
                    limit: this.internal.items.length,
                    ...this.customQueryParams,
                })

                const newItems = data.map(el => {
                    const itemFound = this.internal.items.find(item => item[this.trackBy] === el[this.trackBy])
                    return itemFound ? {...itemFound, ...el} : el
                })

                this.internal.items = newItems
                this.scrollParams.cursorId = cursorId
            } catch (e) {
                this.showErrorText = true
            }
        },
        reload() {
            this.page = 1
            this.perPage = 5
            this.queryParams = {
                query: '',
                ascending: 0,
                byColumn: 0,
            }
            this.scrollParams = {
                cursorId: '',
            }
            this.internal = {
                items: [],
                fields: [],
                count: 0,
                busy: false,
                scrollBusy: false,
                noMoreRecords: false,
            }
            this.isRowDetails = false

            this.setInternalFields()

            if (this.busy) {
                this.internal.busy = this.busy
            }
            if (this.items) {
                this.internal.count = this.items.length
                this.internal.items = [...this.items]
            }
            if (this.defaultPerPage) {
                this.perPage = this.defaultPerPage
            }
            if (this.mode === 'server') {
                this.fetchData()
            }
        },
        setInternalFields() {
            this.isRowDetails = Boolean(this.$scopedSlots['row-details'])
            this.internal.fields = this.isRowDetails
                ? [
                      ...this.fields,
                      {
                          key: 'details',
                          label: '',
                      },
                  ]
                : [...this.fields]
        },
    },
}
</script>

<style lang="scss">
.wit-table__borderless {
    table {
        th > div {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 0.75rem;
            color: #7f7f7f;
        }
        tbody {
            tr {
                td {
                    border: none;
                }
            }
        }
    }
}

.b-table-pagination-wrapper {
    padding-bottom: 75px;
    position: relative;
    .b-table-pagination {
        padding-top: 1rem;
        position: absolute;
        right: 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        .b-table-pagination-per-page-wrapper {
            margin-top: 0.5px;
            color: #7f7f7f;
            font-size: 0.7rem;
            .b-table-pagination-per-page {
                margin: 0 0.2rem;
                width: 90px;
            }
            margin-right: 1.5rem;
        }

        .b-pagination-wrapper {
            display: inline;
            .pagination {
                position: relative;
                margin-bottom: 0.4rem;
                margin-right: 1rem;
                .page-item {
                    .page-link {
                        border: none;
                        color: #7f7f7f;
                        font-size: 0.7rem;
                        background: none;
                    }
                }
                .page-item.active {
                    .page-link {
                        color: white;
                        background: #20a8d8;
                    }
                }
            }
        }

        .current-page {
            color: #7f7f7f;
            font-size: 0.7rem;
            margin-bottom: 0.4rem;
            margin-right: 1rem;
            position: relative;
            text-align: center;
        }
    }
}
.wit-table__toggle-details-div {
    display: flex;
    align-items: center;
    text-align: right;
    float: right;
    .wit-table__toggle-details {
        padding-right: 0.5rem;
    }
}
.wit-table {
    background-color: transparent;
    background: none;
    .b-table-opaque {
        background-color: transparent;
        overflow-x: auto;
    }
    .wit-table {
        thead tr {
            background-color: transparent;
        }
        .table-striped {
            > tbody > tr:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, 0.05);
            }
            > tbody > tr:nth-of-type(even) {
                background-color: transparent;
                background: none;
            }
        }
    }
}
.retrieve-more-row {
    width: 100%;
    margin: 0;
}
</style>
