<template>
    <div class="wit-billing-subscription">
        <div class="wit-billing-subscription__plan">
            <h4>Subscription plan</h4>
            <div class="wit-billing-subscription__card">
                <table>
                    <tbody>
                        <template v-if="activeBillingAccount.active">
                            <tr>
                                <th>Standard (Service fee + usage)</th>
                                <td>Monthly</td>
                            </tr>
                            <tr>
                                <th>Amount</th>
                                <td>{{ currency ? formatCurrency(total - discount, currency) : '' }}</td>
                            </tr>
                            <tr>
                                <th>Next Payment</th>
                                <td>{{ paymentDate }}</td>
                            </tr>

                            <project-router-link
                                class="small-info-text pointer-action wit-billing-subscription__deactivate"
                                :to="`/billing/deactivate/${activeBillingAccount.id}`"
                                >Cancel subscription</project-router-link
                            >
                        </template>
                        <template v-else-if="trialAccount">
                            <tr>
                                <th>Free trial</th>
                                <td v-if="new Date(trialAccount.trialEnd).getTime() > new Date().getTime()">
                                    ends on {{ formatDate(trialAccount.trialEnd) }}
                                </td>
                                <td v-else>ended on {{ formatDate(trialAccount.trialEnd) }}</td>
                            </tr>
                            <tr>
                                <th>Price</th>
                                <td>
                                    <a target="_blank" href="https://witbee.com/pricing">
                                        Go to pricing
                                        <i class="fa fa-external-link-square pointer-action"></i>
                                    </a>
                                </td>
                            </tr>
                            <br />
                            <project-router-link to="/billing?t=billing-details">
                                <b-button class="text-nowrap" variant="primary">
                                    Upgrade to paid plan
                                    <i class="fa fa-long-arrow-right" />
                                </b-button>
                            </project-router-link>
                        </template>
                        <template v-else>
                            <span class="small-info-text">No active subscription</span>
                            <component
                                :is="displayCard ? 'span' : 'project-router-link'"
                                to="/billing?t=billing-details"
                                @click="displayCard ? onActivateClick() : undefined"
                            >
                                <b-button class="text-nowrap" variant="primary">
                                    Activate
                                    <i class="fa fa-long-arrow-right" />
                                </b-button>
                            </component>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="wit-billing-subscription__upcoming-invoice" v-if="upcomingInvoice">
            <h4>Upcoming Invoice</h4>
            <div class="wit-billing-subscription__card">
                <table class="wit-billing-subscription__upcoming-invoice-table">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th class="text-right">Quantity</th>
                            <th class="text-right">Unit price</th>
                            <th class="text-right">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="date in Object.keys(upcomingInvoiceObject)">
                            <tr :key="`${date}-date`" class="wit-billing-subscription__upcoming-invoice-table-date-row">
                                <td colspan="4">{{ formatHeaderDate(date) }}</td>
                            </tr>
                            <template v-for="key in Object.keys(upcomingInvoiceObject[date])">
                                <template v-if="key === 'witcloud_processed_data'">
                                    <tr>
                                        <th>WitCloud processed data (per TB)</th>
                                        <td class="text-right">
                                            {{ calculateQuantity(upcomingInvoiceObject[date][key]) }}
                                        </td>
                                        <td class="text-right"></td>
                                        <td class="text-right"></td>
                                    </tr>
                                    <tr
                                        v-for="(row, index) in upcomingInvoiceObject[date][key]"
                                        :key="`${date}-${key}-${index}`"
                                        class="wit-billing-subscription__upcoming-invoice-table-item-details-row"
                                    >
                                        <td>
                                            <template v-if="row.description.toLowerCase().includes('tier 1')"
                                                >First 1</template
                                            >
                                            <template v-else>2 and above</template>
                                        </td>
                                        <td class="text-right">{{ row.quantity }}</td>
                                        <td class="text-right">
                                            <template v-if="row.description.toLowerCase().includes('tier 1')">{{
                                                formatCurrency(row.unitAmount, row.currency)
                                            }}</template>
                                            <template v-else>
                                                {{
                                                    formatCurrency(
                                                        Number(row.amount) / Math.max(1, Number(row.quantity)),
                                                        row.currency
                                                    )
                                                }}
                                            </template>
                                        </td>
                                        <td class="text-right">{{ formatCurrency(row.amount, row.currency) }}</td>
                                    </tr>
                                </template>

                                <template v-if="key === 'witcloud_active_resources'">
                                    <tr>
                                        <th>WitCloud active resources (per resource)</th>
                                        <td class="text-right">
                                            {{ calculateQuantity(upcomingInvoiceObject[date][key]) }}
                                        </td>
                                        <td class="text-right"></td>
                                        <td class="text-right"></td>
                                    </tr>
                                    <tr
                                        v-for="(row, index) in upcomingInvoiceObject[date][key]"
                                        :key="`${date}-${key}-${index}`"
                                        class="wit-billing-subscription__upcoming-invoice-table-item-details-row"
                                    >
                                        <td>
                                            <template v-if="row.description.toLowerCase().includes('tier 1')"
                                                >First {{ resourceTierCount(row) }}</template
                                            >
                                            <template v-else>{{ resourceTierCount(row) + 1 }} and above</template>
                                        </td>
                                        <td class="text-right">{{ row.quantity }}</td>
                                        <td class="text-right">
                                            <template v-if="row.description.toLowerCase().includes('tier 1')">{{
                                                formatCurrency(row.unitAmount, row.currency)
                                            }}</template>
                                            <template v-else>
                                                {{
                                                    formatCurrency(
                                                        Number(row.amount) / Math.max(1, Number(row.quantity)),
                                                        row.currency
                                                    )
                                                }}
                                            </template>
                                        </td>
                                        <td class="text-right">{{ formatCurrency(row.amount, row.currency) }}</td>
                                    </tr>
                                </template>

                                <template v-if="key === 'witcloud_service_fee'">
                                    <tr>
                                        <th>WitCloud service fee</th>
                                        <td class="text-right">{{ upcomingInvoiceObject[date][key][0].quantity }}</td>
                                        <td class="text-right">
                                            {{
                                                formatCurrency(
                                                    upcomingInvoiceObject[date][key][0].unitAmount,
                                                    upcomingInvoiceObject[date][key][0].currency
                                                )
                                            }}
                                        </td>
                                        <td class="text-right">
                                            {{
                                                formatCurrency(
                                                    upcomingInvoiceObject[date][key][0].amount,
                                                    upcomingInvoiceObject[date][key][0].currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </template>

                                <template v-if="key === 'witcloud_collected_hits'">
                                    <tr>
                                        <th>WitCloud collected hits (per hit)</th>
                                        <td class="text-right">{{ upcomingInvoiceObject[date][key][0].quantity }}</td>
                                        <td class="text-right">
                                            {{
                                                formatCurrency(
                                                    upcomingInvoiceObject[date][key][0].unitAmount,
                                                    upcomingInvoiceObject[date][key][0].currency
                                                )
                                            }}
                                            / 1000000
                                        </td>
                                        <td class="text-right">
                                            {{
                                                formatCurrency(
                                                    upcomingInvoiceObject[date][key][0].amount,
                                                    upcomingInvoiceObject[date][key][0].currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <th class="text-right">Subtotal</th>
                            <td class="text-right">{{ currency ? formatCurrency(subTotal, currency) : '' }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td class="text-right">VAT (23%)</td>
                            <td class="text-right">{{ currency ? formatCurrency(taxTotal, currency) : '' }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <th class="text-right">Total</th>
                            <td class="text-right">{{ currency ? formatCurrency(total, currency) : '' }}</td>
                        </tr>
                        <tr v-if="discount">
                            <td></td>
                            <td></td>
                            <th class="text-right">Discount</th>
                            <td class="text-right">{{ currency ? formatCurrency(discount, currency) : '' }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <th class="text-right">Amount due</th>
                            <td class="text-right">{{ currency ? formatCurrency(total - discount, currency) : '' }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="wit-billing-subscription__card wit-billing-subscription__details">
            <div>
                <p class="wit-billing-subscription__label">Billing details</p>
                <p v-if="displayAddress">
                    <i class="fa fa-home" />
                    {{ displayAddress }}
                </p>
                <p v-if="displayCard">
                    <i class="fa fa-credit-card" />
                    {{ displayCard }}
                </p>

                <project-router-link to="/billing?t=billing-details">
                    <b-button class="text-nowrap" variant="primary">
                        Update details
                        <i class="fa fa-long-arrow-right" />
                    </b-button>
                </project-router-link>
            </div>

            <div>
                <p class="wit-billing-subscription__label">Invoice history</p>
                <wit-table
                    :items="displayInvoices"
                    :fields="columns"
                    striped
                    hide-per-page-selector
                    ref="invoicesTable"
                >
                    <template v-slot:cell(periodStart)="{item}">
                        <div>{{ item.periodStart.toLocaleDateString() }}</div>
                    </template>
                    <template v-slot:cell(periodEnd)="{item}">
                        <div>{{ item.periodEnd.toLocaleDateString() }}</div>
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <div
                            v-b-tooltip="{
                                title: 'Open Invoice',
                                trigger: 'hover',
                                boundary: 'window',
                            }"
                        >
                            <a class="invoice-resource" @click="openInvoice(item)">
                                <i class="fa fa-external-link-square pointer-action"></i>
                            </a>
                        </div>
                    </template>
                </wit-table>
                <project-router-link to="/billing?t=invoices">
                    <b-button class="text-nowrap" variant="primary">
                        All invoices
                        <i class="fa fa-long-arrow-right" />
                    </b-button>
                </project-router-link>
            </div>
        </div>

        <WitModal
            v-model="showActivationModal"
            title="Are you sure?"
            variant="primary"
            customClass="subscription-activate-modal"
            size="md"
        >
            <div class="activate-modal__text">Are you sure you want to activate this billing account?</div>
            <b-row class="d-block text-center">
                <b-button variant="warning" size="md" @click="activateAccount">Yes</b-button>
                <span class="pad-buttons" />
                <b-button variant="danger" size="md" @click="() => (showActivationModal = false)">No</b-button>
            </b-row>
            <b-row>
                <b-col>
                    <Feedback v-if="formError" :state="formError.state" :invalid="formError.message"></Feedback>
                </b-col>
            </b-row>
        </WitModal>
    </div>
</template>

<script>
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import WitTable from '@/components/WitTable.vue'

export default {
    components: {
        WitTable,
        WitModal: () => import('@/components/Modals/WitModal'),
        Feedback: () => import('@/components/Feedback'),
    },
    notifications: {
        invoiceNotFound: {
            type: VueNotifications.types.error,
            title: 'The invoice could not be downloaded',
        },
    },
    props: {
        details: {
            type: Object,
            default: () => null,
        },
        invoices: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            columns: ['periodStart', 'periodEnd', 'actions'],
            upcomingInvoice: [],
            showActivationModal: false,
            formError: {
                state: null,
                message: '',
            },
        }
    },
    computed: {
        ...mapGetters({
            trialAccount: 'billing/trialAccount',
        }),
        activeBillingAccount: {
            get() {
                return this.$store.state.billing.active
            },
        },
        displayAddress() {
            if (
                this.details &&
                this.details.customerDetails &&
                this.details.customerDetails.address &&
                this.details.customerDetails.address.country
            ) {
                return `${this.details.customerDetails.address.line1}${
                    this.details.customerDetails.address.line2 ? ' ' + this.details.customerDetails.address.line2 : ''
                } ${this.details.customerDetails.address.city}, ${
                    this.details.customerDetails.address.country.countryName
                }, ${this.details.customerDetails.address.postalCode}`
            }
        },
        displayCard() {
            if (this.details && this.details.registeredCards && this.details.registeredCards.length) {
                return this.details.registeredCards[0].number
            }
        },
        displayInvoices() {
            return this.invoices.slice(0, 6)
        },
        upcomingInvoiceObject() {
            const upcomingInvoiceObject = {}

            if (this.upcomingInvoice) {
                this.upcomingInvoice.forEach(el => {
                    const {startDate: _startDate, endDate: _endDate} = el

                    const startDate = new Date(_startDate.split(' ')[0]).toISOString().split('T')[0]
                    const endDate = new Date(_endDate.split(' ')[0]).toISOString().split('T')[0]

                    if (!upcomingInvoiceObject[`${startDate} - ${endDate}`]) {
                        upcomingInvoiceObject[`${startDate} - ${endDate}`] = {[el.productName]: [el]}
                    } else {
                        if (upcomingInvoiceObject[`${startDate} - ${endDate}`][el.productName]) {
                            upcomingInvoiceObject[`${startDate} - ${endDate}`][el.productName].push(el)
                        } else {
                            upcomingInvoiceObject[`${startDate} - ${endDate}`][el.productName] = [el]
                        }
                    }
                })
            }

            return upcomingInvoiceObject
        },
        subTotal() {
            return this.upcomingInvoice.reduce((acc, curr) => acc + curr.amount, 0)
        },
        taxTotal() {
            return this.upcomingInvoice.reduce((acc, curr) => acc + curr.tax, 0)
        },
        discount() {
            return this.upcomingInvoice.reduce((acc, curr) => acc + curr.discount, 0)
        },
        total() {
            return this.subTotal + this.taxTotal
        },
        currency() {
            if (this.upcomingInvoice && this.upcomingInvoice.length) {
                return this.upcomingInvoice[0].currency
            }
        },
        paymentDate() {
            const dates = Object.keys(this.upcomingInvoiceObject)
                .map(key => {
                    const [startDate, endDate] = key.split(' - ')

                    return [startDate, endDate]
                })
                .flat()

            const dict = {}
            let paymentDate

            dates.forEach((date, index) => {
                if (dict.hasOwnProperty(date)) {
                    paymentDate = dates[dict[date]]
                    return
                }

                dict[date] = index
            })

            return paymentDate ? this.formatDate(paymentDate) : ''
        },
    },
    watch: {
        activeBillingAccount(value) {
            if (value) {
                this.fetchIncoming()
            }
        },
    },
    created() {
        if (this.activeBillingAccount) {
            this.fetchIncoming()
        }
    },
    methods: {
        async openInvoice(stripeInvoice, download) {
            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/wfirma/invoice/getByStripeInvoiceId/${stripeInvoice.id}`
            )
            const wFirmaInvoice = response.data.data
            if (wFirmaInvoice) {
                const downloadParam = download ? '?download=true' : ''
                window.open(
                    `${process.env.VUE_APP_NODE_API_HOST}/wfirma/invoice/download/${wFirmaInvoice.id}${downloadParam}`,
                    '_blank'
                )
            } else if (stripeInvoice.url) {
                window.open(stripeInvoice.url, '_blank')
            } else {
                this.invoiceNotFound()
            }
        },
        async fetchIncoming() {
            try {
                const {
                    data: {data},
                } = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/${this.activeBillingAccount.id}/upcoming`
                )

                this.upcomingInvoice = data
            } catch {
                this.upcomingInvoice = null
            }

            this.$emit('update:loading', false)
        },

        formatHeaderDate(date) {
            const [startDate, endDate] = date.split(' - ')
            return `${this.formatDate(startDate)} - ${this.formatDate(endDate)}`
        },

        formatDate(date) {
            const options = {year: 'numeric', month: 'short', day: 'numeric'}
            return new Date(date).toLocaleDateString(undefined, options)
        },

        formatItemName(description) {
            const [quantity, name] = description.split(' × ')

            return name
        },

        formatCurrency(amount, currency) {
            const formatter = new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency,
            })
            return formatter.format(amount)
        },

        resourceTierCount(row) {
            if (row.priceCreated >= '2022-11-01') {
                return 10
            }

            return 20
        },

        calculateQuantity(subItemArray) {
            return subItemArray.reduce((acc, curr) => acc + curr.quantity, 0)
        },

        onActivateClick() {
            this.showActivationModal = true
        },

        async activateAccount() {
            this.$store.commit('loading/PROCESSING', `Activating your billing account...`)
            try {
                this.formError = {
                    state: true,
                    message: '',
                }

                const response = await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/billingAccount/activate`, {
                    billingAccountId: this.activeBillingAccount.id,
                    paymentMethod: 'CARD',
                })
                await this.$store.dispatch('billing/setAccount', this.$route.params.id)

                this.showActivationModal = false
                this.$router.go()

                this.$store.commit('loading/PROCESSED')
            } catch (exception) {
                this.formError.state = false
                this.formError.message = exception.response.data.data
                this.$store.commit('loading/PROCESSED')
                this.$errorHandler.report(exception, 'Could not activate billing account')
            }
        },
    },
}
</script>

<style lang="scss">
.wit-billing-subscription {
    $self: &;
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 1300px) {
        grid-template-columns: 2fr 1fr;
    }
    grid-template-rows: auto auto;
    grid-column-gap: 25px;
    grid-row-gap: 25px;

    &__card {
        background: #f0f3f5bb;
        padding: 1rem;
        border-radius: 18px;
        -webkit-box-shadow: 8px 8px rgba(0, 0, 0, 0.1);
        box-shadow: 8px 8px rgba(0, 0, 0, 0.1);
    }

    &__label {
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__details {
        grid-row: span 2;
        margin-top: calc(1.3125rem + 0.5rem);

        > div {
            margin-bottom: 50px;
        }

        .wit-table {
            thead {
                display: none;
                visibility: hidden;
            }

            a,
            a:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    &__upcoming-invoice {
        grid-row-start: 2;
        &-table {
            $self: &;
            width: 100%;

            thead th,
            &-date-row td {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.75rem;
            }

            th,
            td {
                padding: 0.75rem;
            }

            tbody {
                th {
                    font-weight: 600;
                }
            }

            &-date-row {
                background-color: transparent;

                td,
                th {
                    border-top: 1px solid #a4b7c1;
                    padding: 0.75rem 0.75rem 0.2rem;
                }
            }

            &-item-details-row {
                td {
                    padding: 0.3rem 0.75rem;

                    &:first-child {
                        padding-left: 2rem;
                    }
                }
            }

            tfoot {
                tr:first-child {
                    td,
                    th {
                        border-top: 1px solid #a4b7c1;
                    }
                }

                td,
                th {
                    padding: 0.45rem;
                }
            }
        }
    }

    &__deactivate {
        color: red;
        padding: 0.3rem 0.75rem;

        &:hover {
            color: red;
            text-decoration: none;
        }
    }

    &__plan {
        table {
            width: 100%;

            td,
            th {
                padding: 0.3rem 0.75rem;
            }

            td {
                text-align: right;
            }
        }
    }

    p {
        margin-bottom: 10px;

        i {
            margin-right: 10px;
            font-size: 1rem;
            padding-left: 15px;
        }
    }
}

.subscription-activate-modal {
    header {
        h5 {
            font-size: 0.9rem;
        }
    }

    .modal-body {
        .activate-modal__text {
            margin-bottom: 15px;
        }
    }
}
</style>
